import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { showError, showSuccess, debounce } from '@/utils';
import {
	Box,
	ButtonGroup,
	CardActions,
	FormControlLabel,
	FormGroup,
	Stack,
	Tab,
	Tabs,
	Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import transactionModel from '@/models/transaction';

import { useVips } from '@/hooks/vips';

import {
	centerImage,
	saveInStack,
	setActiveBlur,
	setBackgroundColor,
	setBackgroundImg,
	setBlur,
	setBrushSize,
	// setMagicBrush,
	setSection,
	setTab,
	updateImageHasChanges
} from '@/store/editor';
import { Slider } from '@/components/Slider';
import { AddIcon, MinusIcon } from '@/components/Icons';
import { useMedia } from '@/hooks/responsive';
import { Switch } from '@/components/Switch';
import { resetImage } from '@/store/editor/thunks';
import { getErrorParams } from '@/utils/transaction';
import { Tooltip } from '@/components/Tooltip';
import { defaultSettings } from '@/store/editor/utils';

import { ColorSelector } from './ColorSelector';
import { BackgroundSelector } from './BackgroundSelector';

import { getDataToSave } from './utils';
import { AvailableCredits } from './AvailableCredits';
import { BLUR_MAX_VALUE } from './constants';

export function Toolbar({
	handleCloseToolbar = () => {},
	saveDisabled = false
}) {
	const [doneLoading, setDoneLoading] = useState(false);
	const loggedIn = useSelector(state => state.auth.loggedIn);

	const {
		selectedImage,
		section,
		tab,
		images = [],
		resetImage: { loading: resetLoading = false } = {}
	} = useSelector(state => state.editor);
	const mdDown = useMedia('mdDown');

	const currentImage = images.find(item => item.id === selectedImage) || {};

	const { settings = {} } = currentImage;
	const {
		activeBlur,
		blur = defaultSettings.blur,
		/* magicBrush, */
		brushSize,
		backgroundColor,
		background
	} = settings || {};

	const [inputBlurValue, setInputBlurValue] = useState(blur);

	const { enabled: vipsEnabled } = useVips();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const handleChangeTabs = (_, newValue) => {
		dispatch(setSection(newValue));
	};

	const handleChangeBgTabs = newValue => {
		dispatch(setTab(newValue));
	};

	/*
	const toggleMagicBrush = evt => {
		dispatch(setMagicBrush(evt.target.checked));
	};
	*/

	const onSetBrushSize = evt => {
		dispatch(setBrushSize(evt.target.value));
	};

	const toggleBlur = evt => {
		dispatch(setActiveBlur(evt.target.checked));

		const checkedValue = evt?.target?.checked;

		const settingsToSave = {
			activeBlur: checkedValue
		};

		if (!checkedValue && background === 'base') {
			dispatch(setBackgroundImg(null));

			settingsToSave.background = null;
		}

		dispatch(
			saveInStack({
				settings: settingsToSave
			})
		);
	};

	const onSetBlurValue = useCallback(
		debounce(value => {
			dispatch(setBlur(value));
			dispatch(
				saveInStack({
					settings: {
						blur: value
					}
				})
			);
		}, 200),
		[setBlur, saveInStack, dispatch]
	);

	const onChangeBlur = evt => {
		const { value } = evt.target;

		setInputBlurValue(value);
	};

	const handleReset = async () => {
		try {
			await dispatch(resetImage(selectedImage)).unwrap();
			dispatch(centerImage());
			dispatch(saveInStack());
			dispatch(updateImageHasChanges(false));
		} catch (error) {
			showError(...getErrorParams(error, t, navigate));
		}
	};

	const handleDone = async () => {
		try {
			setDoneLoading(true);

			const dataToSave = await getDataToSave(selectedImage, settings);

			await transactionModel.saveBGSettings(selectedImage, dataToSave);
			dispatch(updateImageHasChanges(false));

			showSuccess(t('editor.toolbar.saveSuccess'));
		} catch (error) {
			showError(...getErrorParams(error, t, navigate));
		} finally {
			setDoneLoading(false);
			handleCloseToolbar();
		}
	};

	useEffect(() => {
		onSetBlurValue(inputBlurValue);
	}, [inputBlurValue]);

	useEffect(() => {
		setInputBlurValue(blur);
	}, [selectedImage]);

	const bgTabs = [
		{
			id: 0,
			label: t('editor.toolbar.backgroundTabs.photo'),
			value: 'photo'
		},
		{
			id: 1,
			label: t('editor.toolbar.backgroundTabs.color'),
			value: 'color'
		},
		{
			id: 2,
			label: t('editor.toolbar.backgroundTabs.blur'),
			value: 'blur'
		}
	];

	const brushTabs = [
		{
			id: 0,
			label: t('editor.toolbar.brushTabs.erase'),
			value: 'erase',
			startIcon: <MinusIcon />
		},
		{
			id: 1,
			label: t('editor.toolbar.brushTabs.restore'),
			value: 'restore',
			startIcon: <AddIcon />
		}
	];

	return (
		<Card
			variant="outlined"
			sx={theme => ({
				display: 'flex',
				flexDirection: 'column',
				minHeight: 485,
				height: '100%',
				width: 350,
				position: 'relative',
				overflow: 'visible',

				[theme.breakpoints.down('md')]: {
					width: '100%',
					height: 298,
					minHeight: 'initial',
					borderRadius: '12px 12px 0 0'
				}
			})}
		>
			{loggedIn && !mdDown && <AvailableCredits />}
			<Tabs
				value={section}
				centered
				sx={{ pt: { xs: 0, md: 1 }, maxHeight: { xs: 48, md: 'inherit' } }}
				onChange={handleChangeTabs}
			>
				<Tab value="bg" label={t('editor.toolbar.background')} />
				<Tab value="brush" label={t('editor.toolbar.eraseRestore')} />
			</Tabs>
			{section === 'bg' ? (
				<Box
					sx={{
						bgcolor: '#f7f7f7',
						flex: 1,
						p: 2.5,
						pr: 1,
						pt: { xs: 1.5, md: 2.5 },
						pb: { xs: 1, md: 2.5 },
						maxHeight: { xs: 'initial', md: 'calc(100% - 134px)' }
					}}
				>
					<ButtonGroup fullWidth sx={{ width: '100%', pr: 1.5 }}>
						{!!bgTabs.length &&
							bgTabs.map(item => {
								const isSelected = item.value === tab;

								return (
									<Button
										key={item.id}
										className={isSelected ? 'selected' : ''}
										onClick={() => handleChangeBgTabs(item.value)}
										sx={{ flex: 1, height: 40 }}
									>
										{item.label}
									</Button>
								);
							})}
					</ButtonGroup>
					{tab === 'photo' && (
						<BackgroundSelector
							handleChange={val => {
								dispatch(setBackgroundImg(val));
								dispatch(
									saveInStack({
										settings: {
											backgroundColor: null,
											background: val,
											activeBlur,
											blur
										}
									})
								);
							}}
							value={background}
						/>
					)}
					{tab === 'color' && (
						<ColorSelector
							handleChange={val => {
								dispatch(setBackgroundColor(val));
								dispatch(
									saveInStack({
										settings: {
											backgroundColor: val || null,
											background: null,
											activeBlur: false
										}
									})
								);
							}}
							value={backgroundColor}
						/>
					)}
					{tab === 'blur' && (
						<Stack
							p={2}
							pt={{ xs: 2, md: 3 }}
							pb={{ xs: 0, md: 2 }}
							alignItems="flex-start"
							spacing={1}
						>
							<FormGroup
								onChange={toggleBlur}
								value={activeBlur}
								sx={{ flexDirection: 'row', alignItems: 'center' }}
							>
								<FormControlLabel
									control={
										<Switch
											checked={activeBlur}
											disabled={!vipsEnabled}
										/>
									}
									label={t('editor.toolbar.blur')}
								/>
								{!vipsEnabled && (
									<Tooltip
										icon
										iconSx={{ marginLeft: '-8px' }}
										title={t('editor.toolbar.disabledBlurTooltip')}
									/>
								)}
							</FormGroup>
							<Box width="100%">
								<Typography variant="body1">
									{t('editor.toolbar.blurAmount')}
								</Typography>
								<Slider
									max={BLUR_MAX_VALUE}
									valueLabelDisplay="auto"
									onChange={onChangeBlur}
									value={inputBlurValue}
									defaultValue={blur}
									disabled={!activeBlur}
									sx={{ py: 2.5 }}
								/>
							</Box>
						</Stack>
					)}
				</Box>
			) : (
				<Box
					sx={{
						bgcolor: '#f7f7f7',
						flex: 1,
						p: 2
					}}
				>
					<ButtonGroup fullWidth sx={{ width: '100%' }}>
						{!!brushTabs.length &&
							brushTabs.map(item => {
								const isSelected = item.value === tab;

								return (
									<Button
										key={item.id}
										className={isSelected ? 'selected' : ''}
										onClick={() => handleChangeBgTabs(item.value)}
										sx={{ flex: 1, height: 40 }}
										startIcon={item.startIcon || null}
									>
										{item.label}
									</Button>
								);
							})}
					</ButtonGroup>
					<Stack p={2} pt={3} alignItems="flex-start" spacing={1}>
						<Box width="100%">
							<Typography variant="body1">
								{t('editor.toolbar.brushSize')}
							</Typography>
							<Slider
								valueLabelDisplay="auto"
								onChange={onSetBrushSize}
								min={1}
								value={brushSize}
								defaultValue={brushSize}
							/>
						</Box>
						{/* <FormGroup onChange={toggleMagicBrush} value={magicBrush}>
							<FormControlLabel
								control={<Switch defaultChecked={magicBrush} />}
								label={t('editor.toolbar.magicBrush')}
								/>
							</FormGroup> */}
					</Stack>
				</Box>
			)}

			<CardActions
				sx={theme => ({
					py: 2,
					justifyContent: 'end',

					[theme.breakpoints.down('md')]: {
						py: 1,
						px: 2.5,
						justifyContent: 'space-between'
					}
				})}
			>
				<Button
					color="secondary"
					onClick={handleReset}
					loading={resetLoading}
				>
					{t('common.reset')}
				</Button>
				<Button
					variant={mdDown ? 'text' : 'contained'}
					color="primary"
					onClick={handleDone}
					loading={doneLoading}
					disabled={saveDisabled}
				>
					{t('common.save')}
				</Button>
			</CardActions>
		</Card>
	);
}
